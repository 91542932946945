import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name) errors.name = 'Name is required.';
    if (!formData.email) errors.email = 'Email is required.';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid.';
    if (!formData.message) errors.message = 'Message is required.';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      fetch('https://newry.co.in/test-optima1/contact_submit.php', {
        method: 'POST',
        body: formDataToSend,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setFormSuccess(data.message);
            setFormData({
              name: '',
              email: '',
              message: '',
            });
          } else {
            setFormErrors({ general: data.message });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setFormErrors({ general: 'There was an error sending your message. Please try again.' });
        });
    }
  };

  return (
    <div className="wpb_wrapper">
      <form onSubmit={handleSubmit} className="wpcf7-form init demo" noValidate data-status="init">
        <div style={{ display: 'none' }}>
          <input type="hidden" name="_wpcf7" defaultValue={1911} />
          <input type="hidden" name="_wpcf7_version" defaultValue="5.6.4" />
          <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
          <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f1911-p1871-o2" />
          <input type="hidden" name="_wpcf7_container_post" defaultValue={1871} />
          <input type="hidden" name="_wpcf7_posted_data_hash" defaultValue="" />
        </div>
        <div className="mkd-cf7-contact-form">
          <span className="wpcf7-form-control-wrap" data-name="name">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              size={40}
              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
              aria-required="true"
              aria-invalid={!!formErrors.name}
              placeholder="Name"
            />
          </span>
          {formErrors.name && <p className="error">{formErrors.name}</p>}
          <br />
          <span className="wpcf7-form-control-wrap" data-name="email-333">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              size={40}
              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
              aria-invalid={!!formErrors.email}
              placeholder="E-mail"
            />
          </span>
          {formErrors.email && <p className="error">{formErrors.email}</p>}
          <br />
          <span className="wpcf7-form-control-wrap" data-name="textarea-768">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              cols={40}
              rows={1}
              className="wpcf7-form-control wpcf7-textarea"
              aria-invalid={!!formErrors.message}
              placeholder="Message"
            />
          </span>
          {formErrors.message && <p className="error">{formErrors.message}</p>}
          <br />
          <span className="mkd-submit-button-wrapper">
            <br />
            <input
              type="submit"
              value="Send"
              className="wpcf7-form-control has-spinner wpcf7-submit"
            />
            <span className="mkd-submit-btn-icon arrow_right" />
            <br />
          </span>
        </div>
        <div className="wpcf7-response-output" aria-hidden="true">
          {formSuccess && <div className="form-success">{formSuccess}</div>}
          {formErrors.general && <div className="form-error">{formErrors.general}</div>}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;