import React, { useState } from 'react';
import CareerForm from "../modules/CareerForm";

const Careers = () => {

    const [formText, setFormText] = useState('');

    const handleButtonClick = (text) => {
      setFormText(text);
    };
    
    return <>
        <link rel="stylesheet" href="css/Careers.module.css" />
        <div className="mkd-content">
            <div className="mkd-ps-image-holder">
                <div className="mkd-ps-image mkd-owl-slider">
                    <div className="mkd-ps-image">
                        <picture>
                            <source media="(min-width: 481px)" srcSet="assets/careers/banner.jpg" />
                            <source
                                media="(max-width: 480px)"
                                srcSet="assets/careers/banner-mobile.jpg"
                            />
                            <img
                                className="d-block w-100"
                                src="assets/careers/banner-mobile.jpg"
                                alt="Banner"
                            />
                        </picture>
                    </div>
                </div>
            </div>
            {/*SLIDER END*/}
            <div className="vc_empty_space" style={{ height: 60 }}>
                <span className="vc_empty_space_inner" />
            </div>
            <div className="mkd-container-inner clearfix">
  <div className="mkd-grid-row">
    <div className="mkd-page-content-holder mkd-grid-col-12">
      <section className="wpb-content-wrapper location-advantages">
        <div className="mkd-row-grid-section-wrapper ">
          <div className="mkd-row-grid-section">
            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1516007647796">
                  <div className="wpb_wrapper">
                    <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                      <div className="mkd-eh-item    " data-item-class="mkd-eh-custom-8773" data-1280-1600="0 0 20px 0" data-1024-1280="0 0 20px 0" data-768-1024="0 0 20px 0" data-680-768="0 0 20px 0" data-680="0px">
                        <div className="mkd-eh-item-inner">
                          <div className="mkd-eh-item-content mkd-eh-custom-8773" style={{padding: '0 0 20px 0'}}>
                            <div className="mkd-section-title-holder  ">
                              <div className="mkd-st-inner road">
                                <h2 className="mkd-st-title">
                                  Positions Open
                                </h2>
                                <div className="vc_empty_space" style={{height: '20px'}}>
                                  <span className="vc_empty_space_inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="vc_empty_space" style={{height: '30px'}}>
        <span className="vc_empty_space_inner" />
      </div>
      <section className="wpb-content-wrapper">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1517410755942">
          <div className="wpb_column vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="vc_empty_space" style={{height: '13px'}}><span className="vc_empty_space_inner" /></div>
                <div className="mkd-accordion-holder mkd-ac-default  mkd-toggle mkd-ac-boxed  clearfix">
                  <h6 className="mkd-accordion-title">
                    <span className="mkd-accordion-mark">
                      <span className="mkd_icon_plus icon_plus" />
                      <span className="mkd_icon_minus icon_minus-06" />
                    </span>
                    <span className="mkd-tab-title career-tile">Marketing
                      Manager</span>
                    <a onClick={() => handleButtonClick('Marketing Manager')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                      <span className="mkd-btn-text">Apply Now</span>
                    </a>
                  </h6>
                  <div className="mkd-accordion-content">
                    <div className="mkd-accordion-content-inner">
                      <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                            <br />
                            <h5>Position Summary </h5>  <br />
                          <p>The Marketing Manager from a Real Estate business background will be responsible to monitor, track and analyses the performance of advertising campaigns, managing marketing budget and ensuring that all marketing material is in line with our brand identity. The manager marketing will also be responsible to build and maintain a strong and consistent brand through a wide range of offline and online marketing channels.</p>
                          <br />
                          <h5>Duties and Responsibilities</h5> <br />
                          <ul>
                            <li>To evaluate and maintain a marketing strategy.</li>
                            <li>To develop and manage advertising campaigns.</li>
                            <li>Meets marketing financial objectives by forecasting requirements and preparing annual budgets.</li>
                            <li>To develop and manage marketing content.</li>
                            <li>Identifies marketing opportunities by understanding product requirements.</li>
                            <li>Improves product marketability and profitability by researching, identifying, and capitalizing on sales opportunities.</li>
                            <li>To manage events, exhibitions, promotions, campaigns, and demographic extension.</li>
                            <li>To manage creatives, artwork, visualization, representation, design development in marketing artefacts.</li>
                            <li>To manage and coordinate all event/ground marketing activities and ensure that they are conducted within the budgets.</li>
                            <li>Manage and maintain all website, marketing materials and other corporate communication strategies.</li>
                            <li>Complete media planning, visibility, and advertising planning.</li>
                            <li>To effectively manage enquiry and lead generation activities.</li>
                            <li>To plan and execute branding strategy.</li>
                            <li>To enhance social media and digital Marketing experience.</li>
                            <li>Sustains rapport with key accounts by making periodic visits.</li>
                            <li>Provides information by collecting, analyzing and summarizing marketing data.</li>
                        </ul>
                        <br />
                        <h5>Qualifications</h5> <br />
                        <ul>
                            <li>Bachelors or Master's degree in Sales, business administration or equivalent education.</li>
                            <li>Proven experience as a marketing executive or manager.</li>
                            <li>Experienced in all channels and modes of marketing including digital marketing and offline work.</li>
                            <li>Knowledge in sales and negotiating techniques.</li>
                            <li>Strong business development and networking skills.</li>
                            <li>Skilled in ERP and other business support systems.</li>
                            <li>Effective negotiations skills.</li>
                            <li>Ability to work under pressure.</li>
                            <li>Excellent skills in building and maintaining strong partner relationships.</li>
                        </ul>
                        <br />
                        <h5>Person profile</h5>
                        <ul>
                            <li>Self-driven, energetic, and ambitious.</li>
                            <li>Fast learner and passion for sales.</li>
                            <li>Strong analytical skills and attention to details.</li>
                            <li>Good combination of business skills.</li>
                            <li>Good understanding of interpersonal relationships.</li>
                            <li>Efficient and able to make good and fast decisions.</li>
                            <li>Quality conscious and reliable.</li>
                            <li>Strong written and oral communication skills.</li>
                            <li>Ability to build strong relationships internally and externally.</li>
                            <li>High integrity.</li>
                            <li>Time management skills.</li>
                            <li>Team player.</li>
                        </ul>

                        <br />
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mkd-accordion-title">
                    <span className="mkd-accordion-mark">
                      <span className="mkd_icon_plus icon_plus" />
                      <span className="mkd_icon_minus icon_minus-06" />
                    </span>
                    <span className="mkd-tab-title career-tile"> Assistant Manager Accounts</span>
                    <a onClick={() => handleButtonClick('Assistant Manager Accounts')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                      <span className="mkd-btn-text">Apply Now</span>
                    </a>
                  </h6>
                  <div className="mkd-accordion-content">
                    <div className="mkd-accordion-content-inner">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                            <br />
                            <h5>Position Summary </h5>  <br />
                          <p>Position Summary
As Assistant Manager of Accounts, your role is to assist in overseeing financial activities such as accounts 
payable and receivable. You'll prepare financial statements, ensure compliance with regulations, and 
support budgeting and reporting processes. This role requires strong leadership and financial skills to 
support sustainable growth and effective decision-making.
                          </p>

                          <br />
                          <h5>Duties and Responsibilities</h5> <br />
                          <p><strong>GST (Goods and Services Tax)</strong></p>
                          <p>Ensuring timely and accurate filing of GST returns (GSTR-1, GSTR-3B, GSTR-9, etc.). Conducting 
reconciliation of GST input tax credit with GSTR-2A/28 and ensuring accuracy. Managing and processing 
GST payments and ensuring timely settlement. Assisting in GST audits, preparing necessary 
documentation, and liaising with auditors. Handling applications for GST refunds and ensuring timely 
processing and follow-up.</p>
                        <p>Managing responses to GST notices and queries from tax authorities</p>
                        <br />
                        <strong>TDS (Tax deducted at source)</strong>
                        <p>Ensuring timely deduction and deposit of TDS as per statutory requirements Preparing and filing quarterly 
                        TDS returns (Form 240, 260, 270, etc.). Issuing TDS certificates (Form 16, 16A) to vendors and 
                        employees. Reconciling TDS deducted and deposited with books of accounts and Form 26AS. Managing 
                        TDS assessments, including responding to notices and queries from tax authorities.</p>
                        <br />
                        <strong>Income Tax Compliance</strong>
                        <p>Assisting in the preparation and filing of income tax returns for the company. Calculating and ensuring 
                        timely payment of advance tax. Assisting in tax planning activities to optimize tax liabilities. Coordinating 
                        with auditors for income tax audits and providing necessary information and documentation Handling 
                        income tax notices and queries, preparing responses, and managing follow-up</p>
                        <br />
                        <strong>Handling Tax Cases</strong>
                        <p>Handling Tax Cases
                        Managing and preparing for GST litigation cases, including documentation and representation before tax 
                        authorities and tribunals.</p>
                        <p>Handling income tax disputes and cases, including preparing documentation and representing the company 
                        in hearings.</p>
                        <p>Liaising with legal advisors and consultants for handling complex tax cases and litigation.
                        </p>
                        <p>Maintaining comprehensive records and documentation for all tax-related cases and compliance activities.
                        Implementing and monitoring compliance policies and procedures within the organization.</p>
                        <p>Conducting training sessions for staff on GST, TDS, and income tax compliance.</p>
                        <p>Preparing and presenting regular compliance reports to senior management.</p>
                        <p>Providing support and updates to senior management on compliance-related matters.</p>
                        <br />
                        <h5>Qualifications</h5> <br />
                        <p>Minimum qualification-B.Com (from a regular university) not distance education, M.Com, MBA 
                        (Finance).. or such other master degree</p>
                        <br />
                        <h5>Person profile</h5>
                        <ul>
                            <li>Minimum 4/5 years of experience in real estate sector,</li>
                            <li>Proficiency in Tally, Excel, GST filing, Traces software, TDS filing, e-invoicing.</li>
                            <li>Proficient in reading and understanding the GST/Income tax orders/notices.</li>
                            <li>Should have attended personal hearings and handled officers at GST and Income tax offices.</li>
                            <li>Preferred Male candidates</li>
                            <li>Good communication</li>
                        </ul>

                        <br />
                        <h5>Reporting</h5> <br />
                        <p>The position reports to the Manager / Director – Finance.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mkd-accordion-title">
                    <span className="mkd-accordion-mark">
                      <span className="mkd_icon_plus icon_plus" />
                      <span className="mkd_icon_minus icon_minus-06" />
                    </span>
                    <span className="mkd-tab-title career-tile">Executive Accounts</span>
                    <a onClick={() => handleButtonClick('Executive Accounts')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                      <span className="mkd-btn-text">Apply Now</span>
                    </a>
                  </h6>
                  <div className="mkd-accordion-content">
                    <div className="mkd-accordion-content-inner">
                      <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                            <br />
                            <h5>Position Summary </h5>  <br />
                          <p>The Executive – Finance oversees financial operations to ensure the organization's financial health. They 
lead budgeting, planning, and financial reporting processes, ensuring compliance and strategic alignment 
with company goals. Strong organizational skills and teamwork are essential for excelling in this position.
                          </p>

                          <br />
                          <h5>Duties and Responsibilities</h5> <br />
                          <ul>
                            <li>Maintaining accurate financial records, including ledgers, journals, and invoices.
                            </li>
                            <li> To process invoices and receipts on a daily basis without any back log:</li>
                            <li> timely and accurate filing of all statutory compliances such as GST, TDS, Advance tax etc,</li>
                            <li>To ensure Performing bank and account reconciliations to ensure accuracy.</li>
                            <li>Reconcile creditor and debtor balances and obtain explanation for the same.</li>
                            <li>Ensuring compliance with internal policies.</li>
                            <li>Managing invoicing, payments, and collections processes.</li>
                            <li>Assisting the managers in the preparation for external audits.</li>
                            <li> Organizing and maintaining financial documents and records.</li>
                            <li>Collaborating with other departments to gather necessary financial information and provide financial 
                            support.</li>
                            <li> Providing support to senior finance executives and learning from their expertise.</li>
                            <li>Participating in training programs and continuous learning to enhance financial knowledge and 
                            skills;</li>
                            <li>Assisting in various financial projects and initiatives as assigned by senior management.</li>
                          </ul>
                          
                                                  <br />
                        <h5>Qualifications</h5> <br />
                        <p>Minimum Qualification-B. Com (from a regular university) not distance education.                        </p>
                        <br />
                        <h5>Person profile</h5>
                        <ul>
                            <li>Minimum 2 years of experience in real estate sector.</li>
                            <li>Proficiency in Tally and Excel Financial software and tools to perform accounting and financial 
                            tasks.</li>
                            <li>Proficient in passing accounting entries and filing of GST, TDS returns.</li>
                            <li>Preferred Female candidates</li>
                            <li>Good communication</li>
                        </ul>

                        <br />
                        <h5>Reporting</h5> <br />
                        <p>The position reports to the Manager & Assistant Manager – Finance.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mkd-accordion-title">
                    <span className="mkd-accordion-mark">
                      <span className="mkd_icon_plus icon_plus" />
                      <span className="mkd_icon_minus icon_minus-06" />
                    </span>
                    <span className="mkd-tab-title career-tile">Multi Skill Technician</span>
                    <a onClick={() => handleButtonClick('Multi Skill Technician')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                      <span className="mkd-btn-text">Apply Now</span>
                    </a>
                  </h6>
                  <div className="mkd-accordion-content">
                    <div className="mkd-accordion-content-inner">
                      <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                            <br />
                            <h5>Position Summary </h5>  <br />
                          <p>The Multi skilled technician from a construction background will be responsible to manage and provide a 
comprehensive technical service to required project sites to provide high quality and responsive multi 
skilled maintenance repairs and replacement services.
                          </p>

                          <br />
                          <h5>Duties and Responsibilities</h5> <br />
                          <ul>
                            <li>To carry out planned and responsive repairs and installations as required in construction sites and 
refurbishments activity in completed projects.</li>
                            <li>Responds to repair and maintenance requests in timely fashion.</li>
                            <li>To contribute to identifying and undertaking initiatives aimed at improving the efficiency and 
                            effectiveness of service delivery.</li>
                            <li>To inspect and maintain building systems as per regulations requirement.</li>
                            <li>Contribute to the development of maintenance budget and ensure compliance.</li>
                            <li>Use equipment's including power tools, hand tools and plumbing tools.</li>
                            <li>Read and follow manuals, blueprints, and other written instructions.</li>
                            <li>Collaborate with maintenance team and sub-contractors as required.</li>
                            <li>Maintain, service, clean and properly store equipment's.</li>
                            <li>Regularly check functionality of safety, electrical, plumbing systems.</li>
                            <li>Participate in ensuring maintenance of electrical, plumbing, safety and HVAC systems in multiple 
                            construction sites and projects under maintenance.</li>
                            <li>Any other works as assigned/specified by the management from time to time.</li>
                            </ul>
                          
                                                  <br />
                        <h5>Qualifications</h5> <br />
                        <ul>
                            <li>Diploma in (ITI) or equivalent.</li>
                            <li>Trade Qualification will be an added advantage.</li>
                            <li>Certification in technical skill or equivalent.</li>
                            <li>Proven experience as Multi skilled Technician.</li>
                            <li>Good communication skills.</li>
                            <li>Knowledge of HVAC, plumbing and electrical systems.</li>
                            <li>Knowledge on construction site activities.</li>
                        </ul>
                        <br />
                        <h5>Person profile</h5>
                        <ul>
                            <li>Self-driven, energetic, and ambitious</li>
                            <li>Fast learner and passion for construction.</li>
                            <li>Strong analytical skills and attention to details.</li>
                            <li>Good combination of technical skills.</li>
                            <li>Good understanding of interpersonal relationships.</li>
                            <li>Efficient and able to make good and fast decisions.</li>
                            <li>Quality conscious and reliable.</li>
                            <li>Strong written and oral communication skills.</li>
                            <li>Ability to build strong relationships internally and externally.</li>
                            <li>High integrity.</li>
                            <li>Ability to work with multi discipline projects.</li>
                        </ul>

                        <br />
                        <h5>Reporting</h5> <br />
                        <p>The position reports to the General Manager – Projects.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mkd-accordion-title">
                    <span className="mkd-accordion-mark">
                      <span className="mkd_icon_plus icon_plus" />
                      <span className="mkd_icon_minus icon_minus-06" />
                    </span>
                    <span className="mkd-tab-title career-tile">Manager – Accounts</span>
                    <a onClick={() => handleButtonClick('Manager – Accounts')} itemProp="url" href="#form-pointer" target="_self" className="mkd-btn mkd-btn-small mkd-btn-outline apply-now">
                      <span className="mkd-btn-text">Apply Now</span>
                    </a>
                  </h6>
                  <div className="mkd-accordion-content">
                    <div className="mkd-accordion-content-inner">
                      <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                            <br />
                            <h5>Position Summary </h5>  <br />
                          <p>Experienced Accounts Manager with 10 years of expertise in financial management, budgeting, and 
strategic planning. Proven ability to optimize processes, implement financial controls, and drive profitability 
across diverse industries.
                          </p>

                          <br />
                          <h5>Duties and Responsibilities</h5> <br />
                          <ul>
                            <li><strong>Accounting and Compliance</strong> <br />
                            <strong>General Ledger Maintenance:</strong> Oversee the maintenance of the general ledger and ensure accurate 
bookkeeping. <br />
<strong>Regulatory Compliance:</strong> Ensure compliance with local regulations, including GST, TDS, and other 
tax-related matters. <br /><br />
</li> 
<li><strong>GST:</strong> Ensuring timely and accurate filing of GST returns (GSTR-1, GSTR-3B, GSTR-9, etc.). 
Conducting reconciliation of GST input tax credit with GSTR-2A/28 and ensuring accuracy. Managing 
and processing GST payments and ensuring timely settlement.<br /><br />
</li> 
<li><strong>TDS:</strong> Ensuring timely deduction and deposit of TDS as per statutory requirements. Reconciling TDS 
deducted and deposited with books of accounts and Form 26AS.<br />
<strong>Income Tax:</strong> Calculating and ensuring timely payment of advance tax. Assisting in tax planning 
activities to optimize tax liabilities. <br />
<strong>Audit Management:</strong> Coordinate and manage internal and external audits. <br /><br />
                            </li> 
                            <li><strong>Cash Flow Management</strong> <br />
                            <strong>Cash Flow Forecasting:</strong> Monitor and manage cash flow to ensure liquidity and efficient use of 
resources.  <br />
<strong>Receivables and Payables:</strong> Oversee accounts receivable and payable functions, ensuring timely 
collections and payments.<br /><br />
                            
                            </li> 
                            <li><strong>Financial Planning and Analysis</strong> <br />
                            <strong>Budgeting and Forecasting: :</strong> Develop annual budgets, forecasts, and long-term financial plans. 
Financial Reporting: Prepare monthly, quarterly, and annual financial statements and Management 
Information reports <br />
                            <strong>Variance Analysis: </strong> Analyse variances between actual results and budgets/forecasts, providing 
insights and recommendations. <br />
                            <strong>Financing:</strong> Manage relationships with banks and financial institutions, negotiating loans and other 
                            financing arrangements. <br /><br />
                            </li>
                            <li><strong>Cost Management</strong> <br />
                            <strong>Cost Control:</strong> Monitor and control costs, identifying areas for cost reduction and efficiency 
improvements. <br />
<strong>Project Costing:</strong> Oversee the costing of real estate projects, ensuring accurate and timely cost 
reporting.<br /><br />
                            </li>
                            <li>
                            <strong>Technology and Systems</strong> <br />
                            <strong>ERP Systems:</strong> Oversee the implementation and maintenance of ERP and other financial systems. <br />
                            <strong>Process Improvement: </strong> Continuously improve financial processes and systems for better efficiency 
                            and accuracy.<br /><br />
                            </li>
                            <li><strong>Communication and Coordination</strong> <br />
                            <strong>Stakeholder Management:</strong> Communicate financial performance and strategies to stakeholders, 
including senior management, investors, and board members. <br />
<strong>Interdepartmental Coordination:</strong> Work closely with other departments, such as sales, marketing, and 
operations, to align financial objectives with business goals.<br />
                            </li>
                            </ul>
                          
                                                  <br />
                        <h5>Qualifications</h5> <br />
                        <p>Minimum qualification - CA inter (or) M. Com (or) MBA (Finance) (from a regular university) or such 
other similar degree.</p>
                        <br />
                        <h5>Person profile</h5>
                        <ul>
                            <li>Minimum 10 years of experience in real estate sector.</li>
                            <li>Advanced in Tally Prime, Excel, GST filing, Traces software, TDS filing, e-invoicing, MS office tools; 
Worked with Big Four Firms - Deloitte, KPMG, EY or PWC (either articleship or semi-qualified).</li>
                            <li>Proficient in reading and understanding the GST/Income tax orders/notices.</li>
                            <li> Preferred Male candidates.</li>
                            <li>Good communication Skill.</li> 
                        </ul>

                        <br />
                        <h5>Reporting</h5> <br />
                        <p>The position reports to the Director – Finance.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  </div>
</div>

            <div className="mkd-content-inner">
                <div className="mkd-full-width">
                    <div className="mkd-full-width-inner">
                        <div className="mkd-grid-row">
                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                <section className="wpb-content-wrapper location-advantages">
                                    <div className="mkd-row-grid-section-wrapper ">
                                        <div className="mkd-row-grid-section">
                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1516021087353">
                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                    <div className="vc_column-inner vc_custom_1516007647796">
                                                        <div className="wpb_wrapper">
                                                            <div className="mkd-elements-holder   mkd-one-column  mkd-responsive-mode-768 ">
                                                                <div
                                                                    className="mkd-eh-item    "
                                                                    data-item-class="mkd-eh-custom-8773"
                                                                    data-1280-1600="0 0 20px 0"
                                                                    data-1024-1280="0 0 20px 0"
                                                                    data-768-1024="0 0 20px 0"
                                                                    data-680-768="0 0 20px 0"
                                                                    data-680="0px"
                                                                >
                                                                    <div className="mkd-eh-item-inner">
                                                                        <div
                                                                            className="mkd-eh-item-content mkd-eh-custom-8773"
                                                                            style={{ padding: "0 0 20px 0" }}
                                                                        >
                                                                            <div className="mkd-section-title-holder  ">
                                                                                <div className="mkd-st-inner road">
                                                                                    <h2 className="mkd-st-title">
                                                                                        Our Culture
                                                                                    </h2>
                                                                                    <div
                                                                                        className="vc_empty_space"
                                                                                        style={{ height: 40 }}
                                                                                    >
                                                                                        <span className="vc_empty_space_inner" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/*VISION START*/}
                                <div className="mkd-full-width">
                                    <div className="mkd-full-width-inner">
                                        <div className="mkd-grid-row">
                                            <div className="mkd-page-content-holder mkd-grid-col-12">
                                                <section className="wpb-content-wrapper">
                                                    <div className="mkd-row-grid-section-wrapper ">
                                                        <div className="mkd-row-grid-section">
                                                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1517921277402">
                                                                <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                    <div className="vc_column-inner">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="mkd-portfolio-list-holder mkd-pl-gallery mkd-huge-space mkd-pl-one-column mkd-pl-floating-portfolio-item  mkd-pl-pag-no-pagination ">
                                                                                <div className="mkd-pl-inner mkd-outer-space  clearfix">
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca2.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            At our company, we foster a
                                                                                                            people-centric environment
                                                                                                            where continuous learning and
                                                                                                            adaptation to technological
                                                                                                            advancements are encouraged.
                                                                                                            Our performance-driven culture
                                                                                                            thrives on collaboration and
                                                                                                            support, ensuring equal
                                                                                                            opportunities for individuals
                                                                                                            from diverse backgrounds. We
                                                                                                            actively promote gender
                                                                                                            diversity and inclusion,
                                                                                                            creating a workplace where
                                                                                                            everyone can excel.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca1.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            We believe in engaging our
                                                                                                            employees through various
                                                                                                            initiatives and maintaining a
                                                                                                            positive work environment. Our
                                                                                                            commitment to social and
                                                                                                            sustainable practices extends
                                                                                                            beyond our office walls. We
                                                                                                            prioritize corporate social
                                                                                                            responsibility and
                                                                                                            sustainability, engaging in
                                                                                                            community service and
                                                                                                            environmentally conscious
                                                                                                            operations.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                    <article className="mkd-pl-item mkd-item-space  post-1337 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-about-project">
                                                                                        <div className="mkd-pl-item-inner">
                                                                                            <div className="mkd-pli-image-holder">
                                                                                                <div className="mkd-pli-image">
                                                                                                    <img
                                                                                                        src="assets/freepik/ca3.jpg"
                                                                                                        className="attachment-full size-full wp-post-image"
                                                                                                        alt="a"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mkd-pli-image-drop-shadow"
                                                                                                    style={{
                                                                                                        backgroundColor: "#f2e9e9"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                            <div className="mkd-pli-text-holder">
                                                                                                <div className="mkd-pli-text-wrapper">
                                                                                                    <div className="mkd-pli-text">
                                                                                                        <p
                                                                                                            itemProp="description"
                                                                                                            className="mkd-pli-excerpt"
                                                                                                        >
                                                                                                            Ethics and transparency are
                                                                                                            the cornerstone of our
                                                                                                            business. We uphold ethical
                                                                                                            practices and transparency in
                                                                                                            all our dealings. Our
                                                                                                            customer-centric approach
                                                                                                            prioritizes satisfaction and
                                                                                                            service excellence, with a
                                                                                                            focus on building lasting
                                                                                                            relationships. Clear
                                                                                                            communication with clients and
                                                                                                            stakeholders is integral to
                                                                                                            our success.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </article>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*VISION END*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mkd-container mkd-default-page-template">
                <div className="mkd-container-inner clearfix">
                    <div className="mkd-grid-row">
                        <div className="mkd-page-content-holder mkd-grid-col-12">
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div className="mkd-content-inner">
                    <div className="mkd-full-width">
                        <div className="mkd-full-width-inner">
                            <div className="mkd-grid-row">
                                <div className="mkd-page-content-holder mkd-grid-col-12">
                                    <section className="wpb-content-wrapper">
                                        <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <div className="mkd-google-map-holder">
                                                            <input
                                                                type="hidden"
                                                                className="mkd-snazzy-map"
                                                                defaultValue=""
                                                            />
                                                            <div className="mkd-google-map-overlay" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="enquire-now"
                                            className="mkd-row-grid-section-wrapper mkd-disabled-bg-image-bellow-768"
                                            style={{ backgroundColor: "#ebebeb" }}
                                        >
                                            <div className="mkd-row-grid-section">
                                                <div className="vc_row wpb_row vc_row-fluid">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div
                                                                className="vc_empty_space"
                                                                style={{ height: 35 }}
                                                            >
                                                                <span className="vc_empty_space_inner" />
                                                            </div>
                                                            <div className="wpb_wrapper">
                                                                <h6
                                                                    id="form-phone-container"
                                                                    style={{
                                                                        fontSize: 25,
                                                                        paddingBottom: 6,
                                                                        fontFamily: "metropolis"
                                                                    }}
                                                                >
                                                                    POST YOUR RESUME
                                                                </h6>
                                                                <p>
                                                                    Join us if you thrive in a structured,
                                                                    process-driven culture that respects authority and
                                                                    values adherence to established procedures. We
                                                                    offer a dynamic environment where your skills and
                                                                    talents can flourish, contributing to our
                                                                    collective success. Connect with us
                                                                    <br></br>
                                                                    <a href="mailto:hr@newry.co.in">

                                                                        hr@newry.co.in
                                                                    </a>
                                                                </p>
                                                            </div>
                                                            <div id="form-pointer"></div>
                                                            <CareerForm formText={formText} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default Careers;